<template>
  <div>
    <b-row>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
    // this.onFetchGeneralReport();
  },
}
</script>
